export const PRIMARY_GREEN_LIGHT = "#80D5A4";
export const PRIMARY_GREEN = "#66BA8A";
export const PRIMARY_GREEN_DARK = "#59A67A";

export const PRIMARY_BLUE_LIGHT = "#0350E5";
export const PRIMARY_BLUE = "#033FB4";
export const PRIMARY_BLUE_DARK = "#05338D";

export const SECONDARY_LIGHT = "#FF897E";
export const SECONDARY = "#FD685B";
export const SECONDARY_DARK = "#FA5041";

export const BRIGHT_YELLOW = "#F0EA54";

export const LIGHT_GRAY = "#EFEEEE";

export const ERROR_LIGHT = "#FF2828";
export const ERROR_MAIN = "#E02020";
export const ERROR_DARK = "#B92727";

export const WARNING_LIGHT = "#F6DE4C";
export const WARNING_MAIN = "#F9B600";
export const WARNING_DARK = "#FF7518";

export const SUCCESS_LIGHT = "#42E367";
export const SUCCESS_MAIN = "#3BC15A";
export const SUCCESS_DARK = "#25903E";

export const INFO_LIGHT = "#32C5FF";
export const INFO_MAIN = "#2196F3";
export const INFO_DARK = "#0C5D9D";

export const TEXT_PRIMARY = "#000000DD";
export const TEXT_SECONDARY = "#939493";
export const TEXT_DISABLED = "#BBBBBB";
export const TEXT_DIVIDER = "#0000001E";

export const BLACK = "#000";
export const WHITE = "#fff";

export const GRAY_50 = "#eeeeee";
export const GRAY_100 = "#999696";
export const GRAY_200 = "#939393";
export const GRAY_300 = "#8E8E8C";

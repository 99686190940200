import * as Colors from "../constants/colors";

import { PRIMARY_JAF_DOMUS, SECONDARY_LATO } from "../constants/fonts";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

const overrides = {
  MuiButton: {
    root: {
      borderRadius: 28,
      height: 50,
      "&:focus": {
        outline: "none",
        textDecoration: "none",
      },
    },
  },
};

export const LightTheme = responsiveFontSizes(
  createMuiTheme({
    typography: {
      fontFamily: SECONDARY_LATO,
      headerLink: {
        fontFamily: PRIMARY_JAF_DOMUS,
      },
      h1: {
        fontFamily: PRIMARY_JAF_DOMUS,
        fontWeight: 800,
      },
      h2: {
        fontFamily: PRIMARY_JAF_DOMUS,
        fontWeight: 700,
      },
      h3: {
        fontFamily: PRIMARY_JAF_DOMUS,
        fontWeight: 700,
      },
      h4: {
        fontFamily: PRIMARY_JAF_DOMUS,
        fontWeight: 700,
      },
      h5: {
        fontFamily: PRIMARY_JAF_DOMUS,
        fontWeight: 800,
      },
      h6: {
        // fontFamily: PRIMARY_JAF_DOMUS,
        // fontWeight: 400,
        fontFamily: PRIMARY_JAF_DOMUS,
        fontWeight: 600,
      },
      subtitle1: {
        fontFamily: SECONDARY_LATO,
        fontWeight: 600,
      },
      subtitle2: {
        fontFamily: SECONDARY_LATO,
        fontWeight: 600,
      },
      body1: {
        fontFamily: SECONDARY_LATO,
      },
      body2: {
        fontFamily: SECONDARY_LATO,
      },
      button: {
        fontFamily: SECONDARY_LATO,
        fontWeight: 700,
        fontSize: 16,
        textTransform: "capitalize",
      },
      caption: {
        fontFamily: SECONDARY_LATO,
        fontWeight: 500,
      },
      overline: {
        fontFamily: SECONDARY_LATO,
        fontWeight: 500,
      },
    },
    palette: {
      primary: {
        light: Colors.PRIMARY_BLUE_LIGHT,
        main: Colors.PRIMARY_BLUE,
        dark: Colors.PRIMARY_BLUE_DARK,
        contrastText: Colors.WHITE,
      },
      primaryGreen: {
        light: Colors.PRIMARY_GREEN_LIGHT,
        main: Colors.PRIMARY_GREEN,
        dark: Colors.PRIMARY_GREEN_DARK,
      },
      secondary: {
        light: Colors.SECONDARY_LIGHT,
        main: Colors.SECONDARY,
        dark: Colors.SECONDARY_DARK,
        contrastText: Colors.BLACK,
      },
      text: {
        primary: Colors.TEXT_PRIMARY,
        secondary: Colors.TEXT_SECONDARY,
        disabled: Colors.TEXT_DISABLED,
        divider: Colors.TEXT_DIVIDER,
      },
      error: {
        light: Colors.ERROR_LIGHT,
        main: Colors.ERROR_MAIN,
        dark: Colors.ERROR_DARK,
      },
      warning: {
        light: Colors.WARNING_LIGHT,
        main: Colors.WARNING_MAIN,
        dark: Colors.WARNING_DARK,
      },
      success: {
        light: Colors.SUCCESS_LIGHT,
        main: Colors.SUCCESS_MAIN,
        dark: Colors.SUCCESS_DARK,
      },
      info: {
        light: Colors.INFO_LIGHT,
        main: Colors.INFO_MAIN,
        dark: Colors.INFO_DARK,
      },
      background: {
        default: Colors.WHITE,
      },
    },
    overrides: {
      ...overrides,
    },
  }),
);
